.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: GraphikLight;
    src: url(./assets/fonts/GraphikLight.otf);
}

@font-face {
    font-family: GraphikMedium;
    src: url(./assets/fonts/GraphikMedium.otf);
}

@font-face {
    font-family: GraphikRegular;
    src: url(./assets/fonts/GraphikRegular.otf);
}

@font-face {
    font-family: GraphikBold;
    src: url(./assets/fonts/GraphikBold.otf);
}

@font-face {
    font-family: GraphikSemibold;
    src: url(./assets/fonts/GraphikSemibold.otf);
}

@font-face {
    font-family: GraphikBlack;
    src: url(./assets/fonts/GraphikBlack.otf);
}

.chat-window-container {
    margin: 0;
    padding: 0 !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.chat-container p {
    white-space: pre-line;
}

code {
    white-space: pre-wrap;
    display: inline-block;
    background: #eee;
    padding: 6px;
    border-radius: 6px;
    user-select: all;
}


// Start: Markdown table CSS
:root {
    --color-canvas-default: #ffffff;
    --color-canvas-subtle: #f6f8fa;
    --color-border-default: #d0d7de;
    --color-border-muted: hsla(210, 18%, 87%, 1);
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    display: block;
    margin-top: 0;
    margin-bottom: 16px;
    width: max-content;
    max-width: 100%;
    overflow: auto;
}

tr {
    background-color: var(--color-canvas-default);
    border-top: 1px solid var(--color-border-muted);
}

tr:nth-child(2n) {
    background-color: var(--color-canvas-subtle);
}

td,th {
    padding: 6px 13px;
    border: 1px solid var(--color-border-default);
}

th {
    font-weight: 600;
}

table img {
    background-color: transparent;
}
// End: Markdown table CSS